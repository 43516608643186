import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 170vh;
  background-color: #e6e6e6;
  color: white;
  @media (max-width: 1500px) {
    height: 100%;
    padding: 5vh 0 10vh 0;
  }
`;
const StyledInstagramContainer = styled.div`
  width: 50%;
  color: black;
  @media (max-width: 900px) {
    width: 90%;
    margin-left: 5%;
  }
`;
const StyledInstagramPhotosDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 1vw;
  row-gap: 1vh;
  align-items: stretch;
  @media (max-width: 900px) {
    align-items: center;
  }
`;

const StyledFooterTopPart = styled.div`
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 900px) {
    align-items: flex-start;
    column-gap: 5vw;
    row-gap: 10vh;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledInstagramPhotoLink = styled.a`
  width: 21%;
  @media (max-width: 900px) {
    width: 45%;
  }
  @media (max-width: 450px) {
    width: 49%;
  }
`;
const StyledInstagramPhoto = styled.img`
  width: 100%;
  height: 20vh;
  object-fit: cover;
  object-position: 50% 50%;
  @media (max-width: 900px) {
    width: 100%;
    height: 15vh;
  }
  @media (max-width: 450px) {
    width: 90%;
    height: 20vh;
    object-fit: cover;
    object-position: 50% 50%;
  }
`;

const StyledTitle = styled.h4`
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 10vh;
  @media (max-width: 900px) {
    margin-bottom: 2vh;
  }
`;

const StyledGalleriesDiv = styled.div`
  color: black;
  width: 40%;

  @media (max-width: 900px) {
    width: 90%;
    margin-right: 5%;
  }
  @media (max-width: 450px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 0;
  }
`;

const StyledGalleryContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 3vh;
  column-gap: 2vw;
  @media (max-width: 900px) {
    flex-direction: column;
    row-gap: 5vh;
  }
`;

const StyledGalleryImage = styled.img`
  width: 21%;
  @media (max-width: 900px) {
    width: 60%;
  }

  @media (max-width: 450px) {
    width: 50%;
    margin: 0 auto;
  }
`;

const StyledLine = styled.div`
  width: 70%;
  margin: 10vh auto;
  border-bottom: 0.1rem solid black;
`;

const StyledFooterBottomPart = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 5vw;
  color: black;
  font-size: 0.8rem;
  margin: 5vh 0;
  @media (max-width: 700px) {
    width: 70%;
    flex-wrap: wrap;
    margin: 0 auto;
    row-gap: 2vh;
  }
`;

const StyledNavBarLink = styled.p`
  font-weight: bold;
  &:hover {
    color: #566778;
 
`;

const StyledContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
  font-size: 0.8rem;
  align-items: center;
  margin: 2vh 0 7vh 0;
  row-gap: 1.5vh;
`;

const StyledLinkSpan = styled.span`
  &:hover {
    color: #566778;
  }
`;

const StyledCopyText = styled.p`
  text-align: center;
  @media (max-width: 900px) {
    width: 70%;
  }
`;

export default function Footer() {
  const { t } = useTranslation();
  return (
    <StyledFooterContainer>
      <StyledFooterTopPart>
        <StyledInstagramContainer>
          <a
            href="https://www.instagram.com/erelhl/?hl=en"
            target="_blank"
            style={{ textDecoration: "none", color: "black" }}
          >
            <StyledTitle>{t("footerInstagramTitle")}</StyledTitle>
          </a>
          <StyledInstagramPhotosDiv>
            <StyledInstagramPhotoLink
              href="https://www.instagram.com/erelhl/?hl=en"
              target="_blank"
            >
              <StyledInstagramPhoto
                src="/object_images/obje40.jpeg"
                alt="instagram-photo"
              />
            </StyledInstagramPhotoLink>
            <StyledInstagramPhotoLink
              href="https://www.instagram.com/erelhl/?hl=en"
              target="_blank"
            >
              <StyledInstagramPhoto
                src="/object_images/hale_erel_3.jpeg"
                alt="instagram-photo"
              />
            </StyledInstagramPhotoLink>
            <StyledInstagramPhotoLink
              href="https://www.instagram.com/erelhl/?hl=en"
              target="_blank"
            >
              <StyledInstagramPhoto
                src="/object_images/obje3.jpeg"
                alt="instagram-photo"
              />
            </StyledInstagramPhotoLink>
            <StyledInstagramPhotoLink
              href="https://www.instagram.com/erelhl/?hl=en"
              target="_blank"
            >
              <StyledInstagramPhoto
                src="/object_images/obje38.jpeg"
                alt="instagram-photo"
              />
            </StyledInstagramPhotoLink>
            <StyledInstagramPhotoLink
              href="https://www.instagram.com/erelhl/?hl=en"
              target="_blank"
            >
              <StyledInstagramPhoto
                src="/object_images/obje37.jpeg"
                alt="instagram-photo"
              />
            </StyledInstagramPhotoLink>
            <StyledInstagramPhotoLink
              href="https://www.instagram.com/erelhl/?hl=en"
              target="_blank"
            >
              <StyledInstagramPhoto
                src="/object_images/obje29.jpeg"
                alt="instagram-photo"
              />
            </StyledInstagramPhotoLink>
            <StyledInstagramPhotoLink
              href="https://www.instagram.com/erelhl/?hl=en"
              target="_blank"
            >
              <StyledInstagramPhoto
                src="/object_images/obje17.jpeg"
                alt="instagram-photo"
              />
            </StyledInstagramPhotoLink>
            <StyledInstagramPhotoLink
              href="https://www.instagram.com/erelhl/?hl=en"
              target="_blank"
            >
              <StyledInstagramPhoto
                src="/object_images/obje7.jpeg"
                alt="instagram-photo"
              />
            </StyledInstagramPhotoLink>
            <StyledInstagramPhotoLink
              href="https://www.instagram.com/erelhl/?hl=en"
              target="_blank"
            >
              <StyledInstagramPhoto
                src="/object_images/hale_erel_4.jpeg"
                alt="instagram-photo"
              />
            </StyledInstagramPhotoLink>
            <StyledInstagramPhotoLink
              href="https://www.instagram.com/erelhl/?hl=en"
              target="_blank"
            >
              <StyledInstagramPhoto
                src="/object_images/obje10.jpeg"
                alt="instagram-photo"
              />
            </StyledInstagramPhotoLink>
            <StyledInstagramPhotoLink
              href="https://www.instagram.com/erelhl/?hl=en"
              target="_blank"
            >
              <StyledInstagramPhoto
                src="/object_images/obje27.jpeg"
                alt="instagram-photo"
              />
            </StyledInstagramPhotoLink>
            <StyledInstagramPhotoLink
              href="https://www.instagram.com/erelhl/?hl=en"
              target="_blank"
            >
              <StyledInstagramPhoto
                src="/object_images/obje12.jpeg"
                alt="instagram-photo"
              />
            </StyledInstagramPhotoLink>
          </StyledInstagramPhotosDiv>
        </StyledInstagramContainer>
        <StyledGalleriesDiv>
          <StyledTitle>{t("footerExhibitionTitle")} </StyledTitle>
          <StyledGalleryContentContainer>
            <StyledGalleryImage
              src="/object_images/artcontactistanbul.png"
              alt="art-contact-istanbul"
            />
            <StyledGalleryImage
              src="/object_images/artankara.png"
              alt="art-ankara"
            />
            <StyledGalleryImage
              src="/object_images/act_contemporary.jpeg"
              alt="art-ankara"
            />
            <StyledGalleryImage
              src="/object_images/miami.jpeg"
              alt="red-dot-miami"
            />
            <StyledGalleryImage
              src="/object_images/unfair_milano.jpeg"
              alt="unfair_milano"
            />
          </StyledGalleryContentContainer>
        </StyledGalleriesDiv>
      </StyledFooterTopPart>
      <StyledLine></StyledLine>
      <StyledFooterBottomPart>
        <Link to="/" style={{ textDecoration: "none", color: "black" }}>
          <StyledNavBarLink>{t("footerNavMainPage")} </StyledNavBarLink>
        </Link>
        <Link to="/about" style={{ textDecoration: "none", color: "black" }}>
          <StyledNavBarLink>{t("footerNavAbout")} </StyledNavBarLink>
        </Link>
        <Link
          to="/wall-mounted"
          style={{ textDecoration: "none", color: "black" }}
        >
          <StyledNavBarLink>{t("footerNavWallMounted")}</StyledNavBarLink>
        </Link>
        <Link
          to="/free-standing"
          style={{ textDecoration: "none", color: "black" }}
        >
          <StyledNavBarLink>{t("footerNavFreeStanding")}</StyledNavBarLink>
        </Link>
        <Link to="/contact" style={{ textDecoration: "none", color: "black" }}>
          <StyledNavBarLink>{t("footerNavContact")} </StyledNavBarLink>
        </Link>
        <a
          href="https://www.instagram.com/erelhl/?hl=en"
          target="_blank"
          style={{ textDecoration: "none", color: "black" }}
        >
          <StyledNavBarLink>{t("footerNavInstagram")} </StyledNavBarLink>
        </a>

        <Link
          to="/terms-and-conditions"
          style={{ textDecoration: "none", color: "black" }}
        >
          <StyledNavBarLink>{t("footerNavAgreeement")}</StyledNavBarLink>
        </Link>
      </StyledFooterBottomPart>
      <StyledContactInfoContainer>
        <p>
          <a
            href="mailto: haleerelartstudio@gmail.com"
            style={{ textDecoration: "none", color: "inherit" }}
            target="_blank"
          >
            <StyledLinkSpan>haleerelartstudio@gmail.com</StyledLinkSpan>
          </a>
        </p>
        <StyledCopyText>{t("footerCopyright")}</StyledCopyText>
        <p>Hale Erel, 2024</p>
      </StyledContactInfoContainer>
    </StyledFooterContainer>
  );
}
