import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledAboutContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeroContainer = styled.div`
  background-image: url("./object_images/obje21.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 80vh;
  min-height: 500px;
  background-attachment: fixed;
  @media (max-width: 450px) {
    height: 60vh;
  }
`;

const StyledTransBox = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledHeroContent = styled.div`
  width: 80%;
  margin: 0 auto;
`;
const StyledHeroTitle = styled.h1`
  font-size: 3.5rem;
  color: white;
  font-weight: bolder;
  text-align: center;
`;

const StyledMainContentContainer = styled.div`
  display: flex;
  margin: 15vh 0;
  justify-content: space-evenly;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    row-gap: 5vh;
  }
  @media (max-width: 450px) {
    margin: 2vh 0;
  }
`;

const StyledAboutImage = styled.img`
  width: 30%;
  @media (max-width: 1000px) {
    width: 40%;
  }
  @media (max-width: 550px) {
    width: 80%;
  }
`;

const StyledText = styled.p`
  width: 40%;
  @media (max-width: 900px) {
    width: 50%;
  }
  @media (max-width: 550px) {
    width: 80%;
  }
`;

export default function About() {
  const { t } = useTranslation();

  return (
    <StyledAboutContainer>
      <StyledHeroContainer>
        <StyledTransBox>
          <StyledHeroContent>
            <StyledHeroTitle>{t("aboutTitle")}</StyledHeroTitle>
          </StyledHeroContent>
        </StyledTransBox>
      </StyledHeroContainer>
      <StyledMainContentContainer>
        <StyledText>
          {t("aboutP1")}
          <br /> <br />
          {t("aboutP2")}
        </StyledText>
        <StyledAboutImage
          src="./object_images/hale_erel_4.jpeg"
          alt="about-photo"
        />
      </StyledMainContentContainer>
    </StyledAboutContainer>
  );
}
