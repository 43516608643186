import React from "react";

import ReactModal from "react-modal";

import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import enTranslation from "./components/common/languages/en.json";
import trTranslation from "./components/common/languages/tr.json";

import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";

import "./index.css";
import App from "./App";
import ScrollToTop from "./components/ScrollToTop";
import "./App.css";

import { createRoot } from "react-dom/client";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
ReactModal.setAppElement("#root");

i18n.init({
  resources: {
    en: {
      translation: enTranslation,
    },
    tr: {
      translation: trTranslation,
    },
  },
  interpolation: { escapeValue: false },
  lng: "tr",
  fallbackLng: "tr",
});

root.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <ScrollToTop />
      <App />
    </I18nextProvider>
  </BrowserRouter>
);

reportWebVitals(sendToVercelAnalytics);

/*
//import ReactDOM from "react-dom";
//const root = ReactDOM.createRoot(document.getElementById("root"));
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
*/
