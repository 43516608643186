import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledObjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 28%;
  row-gap: 2vh;
  @media (max-width: 900px) {
    width: 47%;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
`;

const StyledTitle = styled.h2`
  font-size: 0.9rem;
  font-weight: bold;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 50vh;
  object-fit: cover;
  object-position: 10% 10%;

  @media (max-width: 450px) {
    height: auto;
  }
`;

export default function Object(props) {
  const { object } = props;
  const { t } = useTranslation();
  return (
    <StyledObjectContainer key={object.id}>
      <Link
        to={`/object/${object.id}`}
        style={{ textDecoration: "none", color: "black", width: "100%" }}
      >
        {" "}
        <StyledImage src={object.url} />
        <StyledTitle>{`${t("object")} ${object.title}`}</StyledTitle>
      </Link>
    </StyledObjectContainer>
  );
}
