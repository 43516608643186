import { useTranslation } from "react-i18next";
import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const StyledOpenMenu = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
`;

const StyledCloseIcon = styled.img`
  width: 1.2rem;
  cursor: pointer;
  margin: 5vh 5vw 0 auto;
`;

const StyledOpenMenuLinks = styled.nav`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  row-gap: 5vh;
  font-size: 1.5rem;
  padding-top: 2vh;
  text-align: center;
  @media (max-width: 900px) {
    row-gap: 2vh;
  }
`;

const StyledLogo = styled.img`
  width: 30%;
  height: auto;
  margin: 0 auto;
  display: block;
  @media (max-width: 750px) {
    width: 35%;
  }
  @media (max-width: 550px) {
    width: 45%;
  }
  @media (max-width: 450px) {
    width: 55%;
  }
  @media (max-width: 350px) {
    width: 90%;
  }
`;

const StyledNavBarLink = styled.p`
  font-weight: bolder;
  color: black;
  &:hover {
    color: #748da6;
  }
`;

const StyledLanguagesContainer = styled.div`
  margin: 5vh auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor pointer;
`;

const StyledFlagImage = styled.img`
  width: 30px;
  margin-right: 5px;
`;

function Menu(props) {
  const { closeMenu } = props;
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  return (
    <StyledOpenMenu>
      <StyledCloseIcon src="/object_images/close.png" onClick={closeMenu} />
      <StyledOpenMenuLinks>
        <NavLink
          to="/"
          style={{
            textDecoration: "none",
          }}
          onClick={closeMenu}
        >
          <StyledLogo src="/object_images/logo.png" alt="logo" />
        </NavLink>
        <NavLink
          to="/about"
          style={{
            textDecoration: "none",
          }}
          onClick={closeMenu}
        >
          <StyledNavBarLink>{t("menuAbout")}</StyledNavBarLink>
        </NavLink>
        <NavLink
          to="/wall-mounted"
          style={{
            textDecoration: "none",
          }}
          onClick={closeMenu}
        >
          <StyledNavBarLink>{t("menuWallMounted")}</StyledNavBarLink>
        </NavLink>
        <NavLink
          to="/free-standing"
          style={{
            textDecoration: "none",
          }}
          onClick={closeMenu}
        >
          <StyledNavBarLink>{t("menuFreeStanding")}</StyledNavBarLink>
        </NavLink>
        <NavLink
          to="/series"
          style={{
            textDecoration: "none",
          }}
          onClick={closeMenu}
        >
          <StyledNavBarLink>{t("menuSeries")}</StyledNavBarLink>
        </NavLink>
        <NavLink
          to="/contact"
          style={{
            textDecoration: "none",
          }}
          onClick={closeMenu}
        >
          <StyledNavBarLink>{t("menuContact")}</StyledNavBarLink>
        </NavLink>
      </StyledOpenMenuLinks>
      <div>
        {currentLanguage === "tr" ? (
          <StyledLanguagesContainer onClick={() => handleChangeLanguage("en")}>
            <StyledFlagImage src="/languages/english.png" alt="english" />
            <p>English</p>
          </StyledLanguagesContainer>
        ) : (
          <StyledLanguagesContainer onClick={() => handleChangeLanguage("tr")}>
            <StyledFlagImage src="/languages/turkish.png" alt="turkish" />
            <p>Türkçe</p>
          </StyledLanguagesContainer>
        )}
      </div>
    </StyledOpenMenu>
  );
}

export default Menu;

/*







*/

/*
<StyledOpenMenu>
      <StyledCloseIcon src="/object_images/close.png" onClick={closeMenu} />
      <StyledOpenMenuLinks>
        <NavLink
          to="/"
          style={{
            textDecoration: "none",
          }}
          onClick={closeMenu}
        >
          <StyledNavBarLink>Anasayfa</StyledNavBarLink>
        </NavLink>
        <NavLink
          to="/about"
          style={{
            textDecoration: "none",
          }}
          onClick={closeMenu}
        >
          <StyledNavBarLink>Hakkında</StyledNavBarLink>
        </NavLink>
        <NavLink
          to="/wall-mounted"
          style={{
            textDecoration: "none",
          }}
          onClick={closeMenu}
        >
          <StyledNavBarLink>Duvar Panoları</StyledNavBarLink>
        </NavLink>
        <NavLink
          to="/free-standing"
          style={{
            textDecoration: "none",
          }}
          onClick={closeMenu}
        >
          <StyledNavBarLink>Masaüstü</StyledNavBarLink>
        </NavLink>
        <NavLink
          to="/contact"
          style={{
            textDecoration: "none",
          }}
          onClick={closeMenu}
        >
          <StyledNavBarLink>İletişim</StyledNavBarLink>
        </NavLink>
      </StyledOpenMenuLinks>
    </StyledOpenMenu>
    */
