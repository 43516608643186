import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledContactPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeroContainer = styled.div`
  background-image: url("./object_images/obje4.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  height: 80vh;
`;

const StyledTransBox = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5vh;
  padding-left: 5vw;
  justify-content: center;
  align-items: center;
  @media (max-width: 450px) {
    padding: 0;
  }
`;
const StyledHeroTitle = styled.h1`
  font-size: 3.5rem;
  color: white;
  font-weight: bolder;
  @media (max-width: 450px) {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
`;

const StyledContactDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15vh auto;
  width: 50%;
  row-gap: 5vh;
  @media (max-width: 450px) {
    width: 90%;
  }
`;

const StyledContactTitle = styled.h2`
  font-size: 1.5rem;
  color: black;
`;

const StyledEmail = styled.a`
  text-decoration: underline black;
  text-underline-offset: 0.5rem;
  color: black;
  &:hover {
    color: #566778;
    text-decoration: underline grey;
    text-underline-offset: 0.5rem;
  }
`;

const StyledFooterNav = styled.div`
  display: flex;
  column-gap: 2vw;
`;

const StyledFooterContactIcon = styled.img`
  width: 1.3rem;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;
export default function Contact() {
  const { t } = useTranslation();
  return (
    <StyledContactPageContainer>
      <StyledHeroContainer>
        <StyledTransBox>
          <StyledHeroContent>
            <StyledHeroTitle>{t("contactTitle")}</StyledHeroTitle>
          </StyledHeroContent>
        </StyledTransBox>
      </StyledHeroContainer>
      <StyledContactDetailsContainer>
        <StyledContactTitle>{t("contactSubTitle")}</StyledContactTitle>
        <p>{t("contactP1")}</p>

        <StyledEmail href="mailto: haleerelartstudio@gmail.com" target="_blank">
          haleerelartstudio@gmail.com
        </StyledEmail>

        <StyledFooterNav>
          <a
            href="mailto: haleerelartstudio@gmail.com"
            style={{ textDecoration: "none", color: "inherit" }}
            target="_blank"
          >
            <StyledFooterContactIcon
              src="./object_images/mail.png"
              alt="email-icon"
            />
          </a>
          <a
            href="https://www.instagram.com/erelhl/?hl=en"
            target="_blank"
            style={{ textDecoration: "none", color: "black" }}
          >
            <StyledFooterContactIcon
              src="./object_images/instagram-logo.png"
              alt="instagram-icon"
            />
          </a>
        </StyledFooterNav>
      </StyledContactDetailsContainer>
    </StyledContactPageContainer>
  );
}
