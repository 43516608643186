import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledObjectContainer = styled.div`
  display: flex;
  row-gap: 2vh;
  justify-content: space-evenly;
  margin: 15vh 0;
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledImage = styled.img`
  width: 40%;
  @media (max-width: 450px) {
    width: 90%;
  }
`;

const StyledTextsContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  row-gap: 5vh;
  @media (max-width: 450px) {
    width: 90%;
    margin: 0 auto;
  }
`;
const StyledTitle = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: black;
`;

const StyledCategoryText = styled.h4`
  font-size: 0.8rem;
`;
const StyledDirectionsContainer = styled.div`
  display: flex;
  column-gap: 5vw;
`;

const StyledButton = styled.button`
  border: 0.2rem solid black;
  background-color: white;
  color: black;
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: black;
    color: white;
  }
`;

export default function DetailedObject(props) {
  const { data } = props;
  const { id } = useParams();
  const selectedObject = data.filter((object) => object.id === Number(id))[0];
  const { t } = useTranslation();
  return (
    <StyledObjectContainer>
      <StyledImage src={selectedObject.url} alt="object-image" />
      <StyledTextsContainer>
        <StyledDirectionsContainer>
          {selectedObject.id !== 1 && (
            <Link to={`/object/${selectedObject.id - 1}`}>
              <StyledButton>{t("prevButton")}</StyledButton>
            </Link>
          )}

          {selectedObject.id !== data.length && (
            <Link to={`/object/${selectedObject.id + 1}`}>
              <StyledButton>{t("nextButton")}</StyledButton>
            </Link>
          )}
        </StyledDirectionsContainer>
        <StyledTitle>{selectedObject.title}</StyledTitle>
        <Link
          to={`/${selectedObject.type}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <StyledCategoryText>
            {selectedObject.category === "Duvar Panosu"
              ? t("categoryWallMounted")
              : selectedObject.category === "Masaüstü"
              ? t("categoryFreeStanding")
              : t("categorySeries")}
          </StyledCategoryText>
        </Link>
        <p>
          {selectedObject.material === "stoneware"
            ? t("stonewareDescription")
            : selectedObject.material === "porcelain"
            ? t("porcelainDescription")
            : selectedObject.material === "polyester"
            ? t("polyesterDescription")
            : ""}
        </p>
      </StyledTextsContainer>
    </StyledObjectContainer>
  );
}
