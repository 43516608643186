import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Object from "./Object";

const StyledFreeStandingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2vh;
  margin: 10vh auto;
  width: 50%;
  @media (max-width: 900px) {
    margin: 5vh auto;
  }
  @media (max-width: 450px) {
    width: 80%;
    margin-bottom: 5vh;
  }
`;

const StyledTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: bolder;
`;

const StyledLine = styled.div`
  border-bottom: 0.1rem solid #e6e6e6;
  margin-top: 5vh;
  @media (max-width: 900px) {
    margin-top: 2vh;
  }
`;

const StyledObjectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 10vh;
  margin: 10vh 0;
  @media (max-width: 900px) {
    margin: 5vh 0;
  }
`;

export default function FreeStanding(props) {
  const { data } = props;
  const { t } = useTranslation();
  return (
    <StyledFreeStandingContainer>
      <StyledTextsContainer>
        <StyledTitle>{t("freeStandingTitle")} </StyledTitle>
        <p>{t("freeStandingP1")}</p>
        <StyledLine></StyledLine>
      </StyledTextsContainer>
      <StyledObjectsContainer>
        {data.map((object) => (
          <Object key={object.id} object={object} />
        ))}
      </StyledObjectsContainer>
    </StyledFreeStandingContainer>
  );
}
