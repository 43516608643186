import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import LanguageSelector from "./LanguageSelector";

const StyledMenuContainer = styled.div`
  height: 100%;
  padding: 5vh 5vw;
`;

const StyledClosedMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledLogo = styled.img`
  width: 200px;
  @media (max-width: 450px) {
    width: 150px;
  }
`;

const StyledLanguageMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20%;
  @media (max-width: 800px) {
    width: 30%;
  }
`;

const StyledMenuIcon = styled.img`
  width: 25px;
  @media (max-width: 450px) {
    margin-left: auto;
  }
`;

export default function Header(props) {
  const { menuOpen, openMenu } = props;
  return (
    <StyledMenuContainer>
      {!menuOpen && (
        <StyledClosedMenu>
          <StyledLink to="/">
            <StyledLogo src="/object_images/logo.png" alt="logo"></StyledLogo>
          </StyledLink>
          <StyledLanguageMenuContainer>
            <LanguageSelector />
            <StyledMenuIcon src="/object_images/menu.png" onClick={openMenu} />
          </StyledLanguageMenuContainer>
        </StyledClosedMenu>
      )}
    </StyledMenuContainer>
  );
}
