export const data = [
  {
    id: 1,
    url: "/object_images/obje1.jpeg",
    category: "Duvar Panosu",
    type: "wall-mounted",
    title: "1",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 2,
    url: "/object_images/obje2.jpeg",
    category: "Duvar Panosu",
    type: "wall-mounted",
    title: "2",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 3,
    url: "/object_images/obje3.jpeg",
    category: "Duvar Panosu",
    type: "wall-mounted",
    title: "3",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 4,
    url: "/object_images/obje4.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "1",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 5,
    url: "/object_images/obje5.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "2",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 6,
    url: "/object_images/obje6.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "3",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 7,
    url: "/object_images/obje7.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "4",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 8,
    url: "/object_images/obje8.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "5",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 9,
    url: "/object_images/obje9.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "6",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 10,
    url: "/object_images/obje10.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "7",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 11,
    url: "/object_images/obje11.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "8",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 12,
    url: "/object_images/obje12.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "9",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 13,
    url: "/object_images/obje13.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "10",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 14,
    url: "/object_images/obje14.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "11",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 15,
    url: "/object_images/obje15.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "12",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 16,
    url: "/object_images/obje16.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "13",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 17,
    url: "/object_images/obje17.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "14",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 18,
    url: "/object_images/obje18.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "15",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 19,
    url: "/object_images/obje19.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "16",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 20,
    url: "/object_images/obje20.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "17",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 21,
    url: "/object_images/obje21.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "18",
    description: "El ile serbest şekillendirme, 1230 derece pişirim, porselen.",
    material: "porcelain",
  },
  {
    id: 22,
    url: "/object_images/obje22.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "19",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 23,
    url: "/object_images/obje23.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "20",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 24,
    url: "/object_images/obje24.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "21",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 25,
    url: "/object_images/obje25.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "22",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 26,
    url: "/object_images/obje26.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "23",
    description: "El ile serbest şekillendirme, 1230 derece pişirim, porselen.",
    material: "porcelain",
  },
  {
    id: 27,
    url: "/object_images/obje27.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "24",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 28,
    url: "/object_images/obje28.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "25",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 29,
    url: "/object_images/obje29.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "26",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 30,
    url: "/object_images/obje30.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "27",
    description: "El ile serbest şekillendirme, 1230 derece pişirim, porselen.",
    material: "porcelain",
  },
  {
    id: 31,
    url: "/object_images/obje31.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "28",
    description: "El ile serbest şekillendirme, 1230 derece pişirim, porselen.",
    material: "porcelain",
  },
  {
    id: 32,
    url: "/object_images/obje32.jpeg",
    category: "Masaüstü",
    type: "free-standing",
    title: "29",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 33,
    url: "/object_images/obje33.jpeg",
    category: "Duvar Panosu",
    type: "wall-mounted",
    title: "4",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 34,
    url: "/object_images/obje34.jpeg",
    category: "Duvar Panosu",
    type: "wall-mounted",
    title: "5",
    description: "El ile serbest şekillendirme, 1230 derece pişirim, porselen.",
    material: "porcelain",
  },
  {
    id: 35,
    url: "/object_images/obje35.jpeg",
    category: "Duvar Panosu",
    type: "wall-mounted",
    title: "6",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 36,
    url: "/object_images/obje36.jpeg",
    category: "Duvar Panosu",
    type: "wall-mounted",
    title: "7",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 37,
    url: "/object_images/obje37.jpeg",
    category: "Duvar Panosu",
    type: "wall-mounted",
    title: "8",
    description:
      "El ile serbest şekillendirme, 1230 derece pişirim, stoneware.",
    material: "stoneware",
  },
  {
    id: 38,
    url: "/object_images/obje38.jpeg",
    category: "Seri",
    type: "series",
    title: "1",
    description: "Büyük Kibele 75-35-45.",
    material: "polyester",
  },
  {
    id: 39,
    url: "/object_images/obje39.jpeg",
    category: "Seri",
    type: "series",
    title: "2",
    description: "Oturan Kibele 25-12-12.",
    material: "polyester",
  },
  {
    id: 40,
    url: "/object_images/obje40.jpeg",
    category: "Seri",
    type: "series",
    title: "3",
    description: "Oturan Kibele 25-12-12.",
    material: "polyester",
  },
  {
    id: 41,
    url: "/object_images/obje41.jpeg",
    category: "Seri",
    type: "series",
    title: "3",
    description: "Oturan Kibele 25-12-12.",
    material: "polyester",
  },
  {
    id: 42,
    url: "/object_images/obje42.jpeg",
    category: "Seri",
    type: "series",
    title: "4",
    description: "Ayakta Kibele 10-10-30.",
    material: "polyester",
  },
  {
    id: 43,
    url: "/object_images/obje43.jpeg",
    category: "Seri",
    type: "series",
    title: "5",
    description: "Ayakta Kibele 10-10-30.",
    material: "polyester",
  },
  {
    id: 44,
    url: "/object_images/obje44.jpeg",
    category: "Seri",
    type: "series",
    title: "6",
    description: "Ayakta Kibele 10-10-30.",
    material: "polyester",
  },
  {
    id: 45,
    url: "/object_images/obje45.jpeg",
    category: "Seri",
    type: "series",
    title: "7",
    description: "Ayakta Kibele 10-10-30.",
    material: "polyester",
  },
  {
    id: 46,
    url: "/object_images/obje46.jpeg",
    category: "Seri",
    type: "series",
    title: "8",
    description: "Ayakta Kibele 10-10-30.",
    material: "polyester",
  },
];
