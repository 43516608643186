import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledLanguageContainer = styled.div`
  display: flex;
  @media (max-width: 450px) {
    display: none;
  }
`;

const StyledLanguageDropdown = styled.select`
  border: none;
  margin-right: 10px;
  font-size: 1rem;
`;

const StyledFlagImage = styled.img`
  width: 20px;
  margin-right: 5px;
`;
function LanguageSelector() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const handleChangeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };
  return (
    <StyledLanguageContainer>
      {currentLanguage === "tr" ? (
        <StyledFlagImage src="/languages/turkish.png" alt="turkish" />
      ) : (
        <StyledFlagImage src="/languages/english.png" alt="english" />
      )}
      <StyledLanguageDropdown
        name="languages"
        id="languages"
        onChange={handleChangeLanguage}
        defaultValue={currentLanguage}
      >
        <option value={"tr"}>TR</option>
        <option value={"en"}>ENG</option>
      </StyledLanguageDropdown>
    </StyledLanguageContainer>
  );
}

export default LanguageSelector;
