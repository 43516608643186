import React from "react";
import styled from "styled-components";

const StyledTermsConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15vh 0;
`;

const StyledTextsContainer = styled.div`
  width: 50%;
  margin: 0 auto;
  row-gap: 3vh;
  display: flex;
  flex-direction: column;
`;

const StyledMainTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 5vh;
  font-weight: bold;
`;

const StlyedLine = styled.div`
  border-bottom: 0.1rem solid black;
  margin-bottom: 15vh;
`;

const StyledSubTitle = styled.h4`
  font-size: 1.2rem;
  font-weight: bold;
`;

export default function TermsConditions() {
  return (
    <StyledTermsConditionsContainer>
      <StyledTextsContainer>
        <StyledMainTitle>KULLANIM ŞARTLARI</StyledMainTitle>
        <StlyedLine></StlyedLine>
        <p>
          Lütfen sitemizi kullanmadan evvel bu ‘site kullanım şartları’nı
          dikkatlice okuyunuz. Bu alışveriş sitesini kullanan ve alışveriş yapan
          müşterilerimiz aşağıdaki şartları kabul etmiş varsayılmaktadır:
          Sitemizdeki web sayfaları ve ona bağlı tüm sayfalar (‘site’) Hale
          Erel'in ('site yöneticisi') malıdır ve onun tarafından işletilir.
          Sizler (‘Kullanıcı’) sitede sunulan tüm hizmetleri kullanırken
          aşağıdaki şartlara tabi olduğunuzu, sitedeki hizmetten yararlanmakla
          ve kullanmaya devam etmekle; Bağlı olduğunuz yasalara göre sözleşme
          imzalama hakkına, yetkisine ve hukuki ehliyetine sahip ve 18 yaşın
          üzerinde olduğunuzu, bu sözleşmeyi okuduğunuzu, anladığınızı ve
          sözleşmede yazan şartlarla bağlı olduğunuzu kabul etmiş sayılırsınız.{" "}
        </p>
        <p>
          İşbu sözleşme taraflara sözleşme konusu site ile ilgili hak ve
          yükümlülükler yükler ve taraflar işbu sözleşmeyi kabul ettiklerinde
          bahsi geçen hak ve yükümlülükleri eksiksiz, doğru, zamanında, işbu
          sözleşmede talep edilen şartlar dâhilinde yerine getireceklerini beyan
          ederler.
        </p>
        <StyledSubTitle>1. SORUMLULUKLAR</StyledSubTitle>
        <p>
          a.Site yöneticisi, fiyatlar ve sunulan ürün ve hizmetler üzerinde
          değişiklik yapma hakkını her zaman saklı tutar.
        </p>
        <p>
          {" "}
          b.Site yöneticisi, üyenin sözleşme konusu hizmetlerden, teknik
          arızalar dışında yararlandırılacağını kabul ve taahhüt eder.{" "}
        </p>
        <p>
          c.Kullanıcı, sitenin kullanımında tersine mühendislik yapmayacağını ya
          da bunların kaynak kodunu bulmak veya elde etmek amacına yönelik
          herhangi bir başka işlemde bulunmayacağını aksi halde ve 3. Kişiler
          nezdinde doğacak zararlardan sorumlu olacağını, hakkında hukuki ve
          cezai işlem yapılacağını peşinen kabul eder.{" "}
        </p>
        <p>
          d.Kullanıcı, site içindeki faaliyetlerinde, sitenin herhangi bir
          bölümünde veya iletişimlerinde genel ahlaka ve adaba aykırı, kanuna
          aykırı, 3. Kişilerin haklarını zedeleyen, yanıltıcı, saldırgan,
          müstehcen, pornografik, kişilik haklarını zedeleyen, telif haklarına
          aykırı, yasa dışı faaliyetleri teşvik eden içerikler üretmeyeceğini,
          paylaşmayacağını kabul eder. Aksi halde oluşacak zarardan tamamen
          kendisi sorumludur ve bu durumda ‘Site’ yetkilileri, bu tür hesapları
          askıya alabilir, sona erdirebilir, yasal süreç başlatma hakkını saklı
          tutar. Bu sebeple yargı mercilerinden etkinlik veya kullanıcı
          hesapları ile ilgili bilgi talepleri gelirse paylaşma hakkını saklı
          tutar.{" "}
        </p>
        <p>
          e.Sitenin üyelerinin birbirleri veya üçüncü şahıslarla olan ilişkileri
          kendi sorumluluğundadır.{" "}
        </p>
        <StyledSubTitle>2. FİKRİ MÜLKİYET HAKLARI</StyledSubTitle>
        <p>
          2.1. İşbu Site’de yer alan ünvan, işletme adı, marka, patent, logo,
          tasarım, bilgi ve yöntem gibi tescilli veya tescilsiz tüm fikri
          mülkiyet hakları site işletenine veya belirtilen ilgilisine ait olup,
          ulusal ve uluslararası hukukun koruması altındadır. İşbu Site’nin
          ziyaret edilmesi veya bu Site’deki hizmetlerden yararlanılması söz
          konusu fikri mülkiyet hakları konusunda hiçbir hak vermez.
        </p>
        <p>
          2.2. Site’de yer alan bilgiler hiçbir şekilde çoğaltılamaz,
          yayınlanamaz, kopyalanamaz, sunulamaz ve/veya aktarılamaz. Site’nin
          bütünü veya bir kısmı diğer bir internet sitesinde izinsiz olarak
          kullanılamaz.{" "}
        </p>
        <StyledSubTitle>3. GİZLİ BİLGİ</StyledSubTitle>
        <p>
          3.1. Site yöneticisi, site üzerinden kullanıcıların ilettiği kişisel
          bilgileri 3. Kişilere açıklamayacaktır. Bu kişisel bilgiler; kişi
          adı-soyadı, adresi, telefon numarası, cep telefonu, e-posta adresi
          gibi Kullanıcı’yı tanımlamaya yönelik her türlü diğer bilgiyi
          içermekte olup, kısaca ‘Gizli Bilgiler’ olarak anılacaktır.
        </p>
        <p>
          3.2. Kullanıcı, sadece tanıtım, reklam, kampanya, promosyon, duyuru
          vb. pazarlama faaliyetleri kapsamında kullanılması ile sınırlı olmak
          üzere, Site’nin sahibi olan site yöneticisinin kendisine ait iletişim,
          portföy durumu ve demografik bilgilerini iştirakleri ya da bağlı
          bulunduğu grup şirketleri ile paylaşmasına muvafakat ettiğini kabul ve
          beyan eder. Bu kişisel bilgiler site yöneticisi bünyesinde müşteri
          profili belirlemek, müşteri profiline uygun promosyon ve kampanyalar
          sunmak ve istatistiksel çalışmalar yapmak amacıyla
          kullanılabilecektir.
        </p>
        <p>
          3.3. Gizli Bilgiler, ancak resmi makamlarca usulü dairesinde bu
          bilgilerin talep edilmesi halinde ve yürürlükteki emredici mevzuat
          hükümleri gereğince resmi makamlara açıklama yapılmasının zorunlu
          olduğu durumlarda resmi makamlara açıklanabilecektir.
        </p>
        <StyledSubTitle>4. GARANTİ VERMEME</StyledSubTitle>
        <p>
          İŞBU SÖZLEŞME MADDESİ UYGULANABİLİR KANUNUN İZİN VERDİĞİ AZAMİ ÖLÇÜDE
          GEÇERLİ OLACAKTIR. FİRMA TARAFINDAN SUNULAN HİZMETLER "OLDUĞU GİBİ” VE
          "MÜMKÜN OLDUĞU” TEMELDE SUNULMAKTA VE PAZARLANABİLİRLİK, BELİRLİ BİR
          AMACA UYGUNLUK VEYA İHLAL ETMEME KONUSUNDA TÜM ZIMNİ GARANTİLER DE
          DÂHİL OLMAK ÜZERE HİZMETLER VEYA UYGULAMA İLE İLGİLİ OLARAK (BUNLARDA
          YER ALAN TÜM BİLGİLER DÂHİL) SARİH VEYA ZIMNİ, KANUNİ VEYA BAŞKA BİR
          NİTELİKTE HİÇBİR GARANTİDE BULUNMAMAKTADIR.{" "}
        </p>
        <StyledSubTitle>5. KAYIT VE GÜVENLİK</StyledSubTitle>
        <p>
          Kullanıcı, doğru, eksiksiz ve güncel kayıt bilgilerini vermek
          zorundadır. Aksi halde bu Sözleşme ihlal edilmiş sayılacak ve
          Kullanıcı bilgilendirilmeksizin hesap kapatılabilecektir. Kullanıcı,
          site ve üçüncü taraf sitelerdeki şifre ve hesap güvenliğinden kendisi
          sorumludur. Aksi halde oluşacak veri kayıplarından ve güvenlik
          ihlallerinden veya donanım ve cihazların zarar görmesinden site
          yöneticisi sorumlu tutulamaz.
        </p>
        <StyledSubTitle>6. MÜCBİR SEBEP</StyledSubTitle>
        <p>
          Tarafların kontrolünde olmayan; tabii afetler, yangın, patlamalar, iç
          savaşlar, savaşlar, ayaklanmalar, halk hareketleri, seferberlik ilanı,
          grev, lokavt ve salgın hastalıklar, altyapı ve internet arızaları,
          elektrik kesintisi gibi sebeplerden (aşağıda birlikte "Mücbir Sebep”
          olarak anılacaktır.) dolayı sözleşmeden doğan yükümlülükler taraflarca
          ifa edilemez hale gelirse, taraflar bundan sorumlu değildir. Bu sürede
          Taraflar’ın işbu Sözleşme’den doğan hak ve yükümlülükleri askıya
          alınır.
        </p>
        <StyledSubTitle>
          7. SÖZLEŞMENİN BÜTÜNLÜĞÜ VE UYGULANABİLİRLİK
        </StyledSubTitle>
        <p>
          İşbu sözleşme şartlarından biri, kısmen veya tamamen geçersiz hale
          gelirse, sözleşmenin geri kalanı geçerliliğini korumaya devam eder.
        </p>
        <StyledSubTitle>8. SÖZLEŞMEDE YAPILACAK DEĞİŞİKLİKLER</StyledSubTitle>
        <p>
          Site yöneticisi, dilediği zaman sitede sunulan hizmetleri ve işbu
          sözleşme şartlarını kısmen veya tamamen değiştirebilir. Değişiklikler
          sitede yayınlandığı tarihten itibaren geçerli olacaktır.
          Değişiklikleri takip etmek Kullanıcı’nın sorumluluğundadır. Kullanıcı,
          sunulan hizmetlerden yararlanmaya devam etmekle bu değişiklikleri de
          kabul etmiş sayılır.
        </p>
        <StyledSubTitle>9. TEBLİGAT</StyledSubTitle>
        <p>
          İşbu Sözleşme ile ilgili taraflara gönderilecek olan tüm bildirimler,
          Site yöneticisinin bilinen e.posta adresi ve kullanıcının üyelik
          formunda belirttiği e.posta adresi vasıtasıyla yapılacaktır.
          Kullanıcı, üye olurken belirttiği adresin geçerli tebligat adresi
          olduğunu, değişmesi durumunda 5 gün içinde yazılı olarak diğer tarafa
          bildireceğini, aksi halde bu adrese yapılacak tebligatların geçerli
          sayılacağını kabul eder.
        </p>
        <StyledSubTitle>10. DELİL SÖZLEŞMESİ</StyledSubTitle>
        <p>
          Taraflar arasında işbu sözleşme ile ilgili işlemler için çıkabilecek
          her türlü uyuşmazlıklarda Taraflar’ın defter, kayıt ve belgeleri ile
          ve bilgisayar kayıtları ve faks kayıtları 6100 sayılı Hukuk
          Muhakemeleri Kanunu uyarınca delil olarak kabul edilecek olup,
          kullanıcı bu kayıtlara itiraz etmeyeceğini kabul eder.
        </p>
        <StyledSubTitle>11. UYUŞMAZLIKLARIN ÇÖZÜMÜ</StyledSubTitle>
        <p>
          İşbu Sözleşme’nin uygulanmasından veya yorumlanmasından doğacak her
          türlü uyuşmazlığın çözümünde İstanbul (Merkez) Adliyesi Mahkemeleri ve
          İcra Daireleri yetkilidir.
        </p>
      </StyledTextsContainer>
    </StyledTermsConditionsContainer>
  );
}
