import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledMainPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeroContainer = styled.div`
  background-image: url("./object_images/obje18.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  height: 80vh;
  @media (max-width: 850px) {
    height: 60vh;
  }
  @media (max-width: 450px) {
    align-items: center;
    text-align: center;
    height: 60vh;
  }
`;

const StyledTransBox = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledTransBoxTwo = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5vh;
  padding-left: 5vw;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    width: 95%;
    margin: 0 auto;
  }
`;

const StyledHeroText = styled.p`
  color: white;
  font-size: 3rem;
  width: 60%;
  text-align: center;
  font-weight: bold;
  @media (max-width: 900px) {
    width: 80%;
  }
  @media (max-width: 550px) {
    margin: auto;
    height: 80%;
    padding-bottom: 2vh;
    font-size: 2rem;
  }
`;

const StyledImagesContentContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 15vh 0;
  width: 90%;
  margin: 0 auto;
  column-gap: 1vw;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    row-gap: 5vh;
  }
  @media (max-width: 450px) {
    padding: 3vh 0;
  }
`;

const StlyedMainContentImageContainer1 = styled.div`
  display: flex;
  align-items: flex-start;
  background-image: url("./object_images/sample12.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 80vh;
  @media (max-width: 850px) {
    height: 60vh;
  }
`;

const StlyedMainContentImageContainer2 = styled.div`
  display: flex;
  align-items: flex-start;
  background-image: url("./object_images/obje25.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  height: 80vh;
  @media (max-width: 850px) {
    height: 60vh;
  }
`;

const StlyedMainContentImageContainer3 = styled.div`
  display: flex;
  align-items: flex-start;
  background-image: url("./object_images/obje39.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  height: 80vh;
  @media (max-width: 850px) {
    height: 60vh;
  }
`;

const StyledImageText = styled.p`
  font-size: 1.2rem;
  color: white;
  margin: 2vh 0 0 2vh;
  font-weight: bold;
  @media (max-width: 900px) {
    font-size: 1.5rem;
  }
`;

const StyledTextsContentContainer = styled.div`
  margin: 30vh auto;
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 5vh;
  @media (max-width: 900px) {
    margin: 8vh auto;
    flex-direction: column;
    row-gap: 15vh;
  }
  @media (max-width: 450px) {
    margin: 20vh auto;
    row-gap: 15vh;
  }
`;

const StyledQuote = styled.p`
  font-size: 2rem;
  width: 60%;
  font-weight: bold;
  line-height: 3.5rem;
  margin: 0 auto;

  @media (max-width: 900px) {
    width: 85%;
    text-align: center;
  }

  @media (max-width: 450px) {
    font-size: 1.5rem;
    line-height: 3rem;
    width: 90%;
  }
`;

const StyledArtistImage = styled.img`
  width: 30%;
  @media (max-width: 900px) {
    width: 60%;
  }
  @media (max-width: 450px) {
    width: 90%;
  }
`;

export default function Mainpage() {
  const { t } = useTranslation();
  return (
    <StyledMainPageContainer>
      <StyledHeroContainer>
        <StyledTransBox>
          <StyledHeroContent>
            <StyledHeroText>{t("mainHeroTitle")}</StyledHeroText>
          </StyledHeroContent>
        </StyledTransBox>
      </StyledHeroContainer>
      <StyledImagesContentContainer>
        <Link to="/wall-mounted" className="StyledLink">
          <StlyedMainContentImageContainer1>
            <StyledTransBoxTwo>
              <StyledImageText>{t("mainWallMountedTitle")}</StyledImageText>
            </StyledTransBoxTwo>
          </StlyedMainContentImageContainer1>
        </Link>
        <Link to="/free-standing" className="StyledLink">
          <StlyedMainContentImageContainer2>
            <StyledTransBoxTwo>
              <StyledImageText>{t("mainFreeStandingTitle")}</StyledImageText>
            </StyledTransBoxTwo>
          </StlyedMainContentImageContainer2>
        </Link>
        <Link to="/series" className="StyledLink">
          <StlyedMainContentImageContainer3>
            <StyledTransBoxTwo>
              <StyledImageText>{t("mainSeriesTitle")}</StyledImageText>
            </StyledTransBoxTwo>
          </StlyedMainContentImageContainer3>
        </Link>
      </StyledImagesContentContainer>
      <StyledTextsContentContainer>
        <StyledQuote>{t("mainQuote")}</StyledQuote>
        <StyledArtistImage
          src="./object_images/hale_erel_3.jpeg"
          alt="hale-erel"
        />
      </StyledTextsContentContainer>
    </StyledMainPageContainer>
  );
}
