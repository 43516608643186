import React, { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
import "./App.css";
import { data } from "./components/data";
import Header from "./components/header/Header";
import Menu from "./components/modal-menu/Menu";
import Mainpage from "./components/Mainpage";
import WallMounted from "./components/WallMounted";
import FreeStanding from "./components/FreeStanding";
import Series from "./components/Series";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import DetailedObject from "./components/DetailedObject";
import TermsConditions from "./components/TermsConditions";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  let modalStyle = {
    content: {
      backgroundColor: "white",
      height: "100%",
      right: 0,
      left: "auto",
      top: 0,
      opacity: 0.85,
    },
  };

  return (
    <div>
      <Header menuOpen={menuOpen} openMenu={openMenu} />
      <ReactModal
        isOpen={menuOpen}
        style={modalStyle}
        onRequestClose={closeMenu}
      >
        <Menu closeMenu={closeMenu} />
      </ReactModal>
      <Routes>
        <Route path="/" element={<Mainpage />} />
        <Route
          path="/wall-mounted"
          element={
            <WallMounted
              data={data.filter((object) => object.type === "wall-mounted")}
            />
          }
        />
        <Route
          path="/free-standing"
          element={
            <FreeStanding
              data={data.filter((object) => object.type === "free-standing")}
            />
          }
        />
        <Route
          path="/series"
          element={
            <Series data={data.filter((object) => object.type === "series")} />
          }
        />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/object/:id" element={<DetailedObject data={data} />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
